<template>
  <v-popup v-model:visible="openPoup" overlay closeOverlay onOpenLoadPage keyPopup="popupGlobal" :closeable="false">
    <template #header>
            <span
                class="i-custom-close transform rotate-180 w-5.5 h-5.5 text-black/50 absolute z-5 top-3 right-3 lg:top-4.5 lg:right-4.5 cursor-pointer"
                @click="openPoup = false"
            ></span>
      <!-- <div class="flex justify-center mb-6">
          <div class="w-15.5 h-15.5">
              <img src="/images/global/icon-popup-analysis.svg" alt="icon" class="w-full h-full object-contain" />
          </div>
      </div> -->
    </template>
    <div class="flex flex-col gap-3">
      <p class="text-#121212 text-25px leading-10 font-[550] text-center">
        {{ dataRender?.title }}
      </p>
      <div class="w-full">
        <div v-html="dataRender?.content" class="post-content max-h-60 lg:max-h-100 overflow-y-auto pr-3"></div>
      </div>
    </div>

    <template #footer>
      <div class="flex flex-col gap-3 lg:mt-10 mt-5">
        <button-primary
            @click="openPoup = false"
            :title="dataRender?.btn_title"
            class="w-full h-13.5"
        ></button-primary>
        <nuxt-link
            class="text-black/60 text-sm leading-6 flex-1 font-normal text-center hover:text-primary duration-200"
            @click="openPoup = false"
            target="_blank"
            :to="privacy_policy"
        >
          {{ $t('PRIVACY_POLICY_DETAILS') }}
        </nuxt-link>
      </div>
    </template>
  </v-popup>
</template>
<script lang="ts">
export default {
  name: 'popup-global'
}
</script>
<script setup lang="ts">
const {t} = useI18n()

defineProps({
  title: {
    type: String as any
  },
  intro: {
    type: String as any
  }
})

const openPoup = ref(false)
const popup_global = inject('globalSettings')?.popup[0]
const links_global = inject('globalSettings')?.links[0]
const dataRender = ref()

const privacy_policy = ref()
const terms_condition = ref()

const routesRender = () => {
  links_global?.translations.map((item) => {
    if (item?.languages_code == useRoute().params.langCode) {
      privacy_policy.value = item?.privacy_policy
      terms_condition.value = item?.terms_condition
    }
  })
}

onMounted(() => {
  routesRender()
  popup_global?.translations.map((item) => {
    if (useRoute().params.langCode == item?.languages_code) {
      dataRender.value = item
    }
  })
})
</script>

<style>
.box-content-popup-popupGlobal {
  border-radius: 20px;
  @apply p-5 md:p-10 pt-8 md:pt-12 w-full md:w-120;
}
</style>
